<template>
  <transition name="fade" mode="out-in">
    <component :is="layout" v-if="layout" />
  </transition>
  <DsNavigationLoader />
  <DsToastNotifier />
</template>

<script setup lang="ts">
import { shallowRef, watch } from "vue";

import { DsNavigationLoader, DsToastNotifier } from "@devsalsa/vue-core";

import AccountLayout from "@/core/modules/account/components/AccountLayout.vue";
import AuthenticatedLayout from "@/core/shared/components/Layout/AuthenticatedLayout.vue";

import { useRoute } from "vue-router";

import useConnectionChecker from "@/shared/composables/ConnectionChecker";
import PageNotFoundLayout from "@/shared/modules/page-not-found/components/PageNotFoundLayout.vue";

const layouts = {
  AccountLayout,
  PageNotFoundLayout,
};
const layout = shallowRef();
const route = useRoute();

useConnectionChecker();

watch(
  () => route,
  (to) => {
    if (to.meta.layout !== undefined) {
      layout.value = Reflect.get(layouts, String(to.meta.layout));
    } else {
      layout.value = AuthenticatedLayout;
    }
  },
  {
    deep: true,
  }
);
</script>

<style lang="scss">
.app-content {
  margin-top: 64px;
  min-height: calc(100vh - 192px);

  @screen sm {
    min-height: calc(100vh - 128px);
  }
}
</style>
