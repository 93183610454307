import { ApiService } from "@/core/shared/services/ApiService";

interface ApiVersion {
  version: string;
}

export default class StatusService {
  static get(): Promise<ApiVersion> {
    return ApiService.get("/status");
  }
}
