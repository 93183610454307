import { ApiService } from "@/core/shared/services/ApiService";

import type { AxiosProgressEvent } from "axios";

import type {
  Company,
  CompanyLogs,
  CompanyPaymentResponse,
  CompanySubscriptionLinks,
  CompanyUpdated,
  CompanyWatermarksResponse,
  CompanyWithdrawals,
  CompanyWithdrawalsResponse,
  CompanyWithdrawalsUpdated,
  FileWatermarkResponse,
  ImpersonateToken,
  SearchCompanyPaymentFilters,
  UpdateWatermarkResponse,
  Watermark,
} from "@/modules/company/services/CompanyService.types";
import type { WalletTransactionSearchFilters } from "@/shared/services/SearchService.types";
import type {
  CompanyWalletFundsFormData,
  PromotionWalletFundsFormData,
  PromotionWalletTransactionsResponse,
  WalletFundsUpdated,
  WalletTransactionsResponse,
} from "@/shared/services/WalletService.types";

/**
 * @class CompanyService
 */
export default class CompanyService {
  /**
   * Get data of a company
   * @endpoint /admin/companies/${companyId}
   * @httpMethod GET
   * @param companyId {number} CompanyImpersonation ID
   * @throws {BadRequestApiServiceError}
   * @return {Promise<Company>}
   */
  static get(companyId: number): Promise<Company> {
    return ApiService.get(`/admin/companies/${companyId}`);
  }

  /**
   * Suspend company
   * @endpoint /admin/companies/${companyId}/suspend
   * @httpMethod PUT
   * @param companyId {number} Company Impersonation ID
   * @param suspendedReason {string} Suspended reason
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CompanyUpdated>}
   */
  static suspend(
    companyId: number,
    suspendedReason: string
  ): Promise<CompanyUpdated> {
    return ApiService.put(`/admin/companies/${companyId}/suspend`, {
      suspended_reason: suspendedReason,
    });
  }

  /**
   * Unsuspend company
   * @endpoint /admin/companies/${companyId}/unsuspend
   * @httpMethod PUT
   * @param companyId {number} CompanyImpersonation ID
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CompanyUpdated>}
   */
  static unsuspend(companyId: number): Promise<CompanyUpdated> {
    return ApiService.put(`/admin/companies/${companyId}/unsuspend`);
  }

  /**
   * Update company data
   * @endpoint /admin/companies/${companyId}
   * @httpMethod PUT
   * @param companyId {number} CompanyImpersonation ID
   * @param companyName {string} CompanyImpersonation name
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CompanyUpdated>}
   */
  static edit(companyId: number, companyName: string): Promise<CompanyUpdated> {
    return ApiService.put(`/admin/companies/${companyId}`, {
      company_id: companyId,
      name: companyName,
    });
  }

  /**
   * Get impersonate company using an identifier
   * @endpoint /admin/companies/{companyId}/impersonate
   * @httpMethod GET
   * @param companyId {number} CompanyImpersonation ID
   * @throws {BadRequestApiServiceError}
   * @return {Promise<ImpersonateToken>}
   */
  static impersonate(companyId: number): Promise<ImpersonateToken> {
    return ApiService.get(`/admin/companies/${companyId}/impersonate`);
  }

  /**
   * Get company logs
   * @endpoint /admin/companies/${companyId}/logs/${page}/${rows}
   * @httpMethod GET
   * @param companyId Company impersonation id
   * @param page Page
   * @param rows Number of rows to load
   * @return {Promise<CompanyLogs>}
   */
  static logs(companyId: number, page = 0, rows = 25): Promise<CompanyLogs> {
    return ApiService.get(`/admin/companies/${companyId}/logs/${page}/${rows}`);
  }

  /**
   * Get company wallet logs
   * @endpoint /admin/companies/{companyId}/system-wallet-transactions/{page}/{rows}
   * @httpMethod GET
   * @param companyId Company impersonation id
   * @param marketplaceId
   * @param page Page
   * @param rows Number of rows to load
   * @throws {BadRequestApiServiceError}
   * @return {Promise<WalletTransactionsResponse>}
   */
  static getWalletTransactions(
    companyId: number,
    marketplaceId: number,
    page = 0,
    rows = 25
  ): Promise<WalletTransactionsResponse> {
    return ApiService.post(
      `/admin/companies/${companyId}/system-wallet-transactions/${page}/${rows}`,
      {
        marketplace_id: marketplaceId,
      }
    );
  }

  /**
   * Get promotion wallet logs
   * @endpoint /admin/companies/{companyId}/promo-wallet/transactions/{page}/{rows}
   * @httpMethod POST
   * @param companyId Company impersonation id
   * @param page Page
   * @param rows Number of rows to load
   * @param filters {WalletTransactionSearchFilters} Search filters
   * @throws {BadRequestApiServiceError}
   * @return {Promise<PromotionWalletTransactionsResponse>}
   */
  static async getPromotionWalletTransactions(
    companyId: number,
    page = 0,
    rows = 25,
    filters: WalletTransactionSearchFilters = { sort: "newest", reverse: 0 }
  ): Promise<PromotionWalletTransactionsResponse> {
    return ApiService.post(
      `/admin/companies/${companyId}/promo-wallets/transactions/${page}/${rows}`,
      filters
    );
  }

  /**
   * Add funds to company wallet
   * @endpoint /admin/add-funds/company
   * @httpMethod POST
   * @param data {CompanyWalletFundsFormData} Data
   * @throws {BadRequestApiServiceError}
   * @return {Promise<WalletFundsUpdated>}
   */
  static addWalletFunds(
    data: CompanyWalletFundsFormData
  ): Promise<WalletFundsUpdated> {
    return ApiService.post("/admin/add-funds/company", { ...data });
  }

  /**
   * Add funds to promotion wallet
   * @endpoint /admin/companies/promo-wallets/add-funds
   * @httpMethod PUT
   * @param data {PromotionWalletFundsFormData} Data
   * @throws {BadRequestApiServiceError}
   * @return {Promise<WalletFundsUpdated>}
   */
  static addPromotionWalletFunds(
    data: PromotionWalletFundsFormData
  ): Promise<WalletFundsUpdated> {
    return ApiService.put("/admin/companies/promo-wallets/add-funds", {
      ...data,
    });
  }

  /**
   * Set all content private for a company
   * @endpoint /admin/companies/{companyId}/set-content-privacy
   * @httpMethod POST
   * @param companyId {number}
   * @param privacy {number}
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CompanyUpdated>}
   */
  static setContentPrivacy(
    companyId: number,
    privacy: number
  ): Promise<CompanyUpdated> {
    return ApiService.put(`/admin/companies/${companyId}/set-content-privacy`, {
      content_privacy: privacy,
    });
  }

  /**
   * Approve KYC for the company
   * @endpoint /admin/companies/{companyId}/set-kyc-approved
   * @httpMethod POST
   * @param companyId {number}
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CompanyUpdated>}
   */
  static approveKyc(companyId: number): Promise<CompanyUpdated> {
    return ApiService.put(`/admin/companies/${companyId}/set-kyc-approved`);
  }

  /**
   * Reject KYC for the company
   * @endpoint /admin/companies/{companyId}/set-kyc-rejected
   * @httpMethod POST
   * @param companyId {number}
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CompanyUpdated>}
   */
  static rejectKyc(companyId: number): Promise<CompanyUpdated> {
    return ApiService.put(`/admin/companies/${companyId}/set-kyc-rejected`);
  }

  /**
   * Add company as VIP
   * @export /admin/companies/${companyId}/set-vip
   * @httpMethod PUT
   * @param company_id {number} Id of company_id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CompanyUpdated>}
   */
  static setVip(company_id: number): Promise<CompanyUpdated> {
    return ApiService.put(`/admin/companies/${company_id}/set-vip`);
  }

  /**
   * Remove company as VIP
   * @export /admin/companies/${companyId}/unset-vip
   * @httpMethod PUT
   * @param company_id {number} Id of company_id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CompanyUpdated>}
   */
  static unsetVip(company_id: number): Promise<CompanyUpdated> {
    return ApiService.put(`/admin/companies/${company_id}/unset-vip`);
  }

  /**
   * Get a list of companies withdrawals
   * @endpoint /admin/companies/payments-approvals/{page}/{rows}
   * @httpMethod GET
   * @param page {number} Page number
   * @param rows {number} Rows per page
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CompanyWithdrawalsResponse>}
   */

  static getWithdrawalsPendingApproval(
    page = 0,
    rows = 25
  ): Promise<CompanyWithdrawalsResponse> {
    return ApiService.get(
      `/admin/companies/payments-approvals/${page}/${rows}`
    );
  }

  /**
   * Get data of latest withdrawal pending approval
   * @endpoint /admin/companies/latest-payments-approvals
   * @httpMethod GET
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CompanyWithdrawals>}
   */
  static getLatestWithdrawalPendingApproval(): Promise<CompanyWithdrawals> {
    return ApiService.get(`/admin/companies/latest-payments-approvals`);
  }

  /**
   * Get a list of companies payments
   * @endpoint /admin/companies/${companyId}/payments/${page}/${rows}
   * @httpMethod POST
   * @param companyId
   * @param filters {[]} Search company payments filters
   * @param page
   * @param rows
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CompanyPaymentResponse>}
   */
  static searchCompanyPayments(
    companyId: number,
    filters?: SearchCompanyPaymentFilters,
    page = 0,
    rows = 25
  ): Promise<CompanyPaymentResponse> {
    return ApiService.post(
      `/admin/companies/${companyId}/payments/${page}/${rows}`,
      filters
    );
  }

  /**
   * Approve company withdrawal approval
   * @endpoint /admin/companies/${companyId}/allow-payments
   * @httpMethod PUT
   * @param companyId
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CompanyWithdrawalsUpdated>}
   */
  static approveWithdrawal(
    companyId: number
  ): Promise<CompanyWithdrawalsUpdated> {
    return ApiService.put(`/admin/companies/${companyId}/allow-payments`);
  }

  /**
   * Deny company withdrawal approval
   * @endpoint /admin/companies/${companyId}/disallow-payments
   * @httpMethod PUT
   * @param companyId
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CompanyWithdrawalsUpdated>}
   */
  static denyWithdrawal(companyId: number): Promise<CompanyWithdrawalsUpdated> {
    return ApiService.put(`/admin/companies/${companyId}/disallow-payments`);
  }

  /**
   * @endpoint /admin/companies/watermark-approvals/${page}/${rows}
   * @httpMethod GET
   * @param page
   * @param rows
   */
  static listWatermarksPendingApproval(
    page = 0,
    rows = 25
  ): Promise<CompanyWatermarksResponse> {
    return ApiService.get(
      `/admin/companies/watermark-approvals/${page}/${rows}`
    );
  }

  /**
   * Get data of latest watermark pending approval
   * @endpoint /admin/companies/latest/watermark-approvals
   * @httpMethod GET
   * @throws {BadRequestApiServiceError}
   * @return {Promise<Watermark>}
   */
  static getLatestWatermarkPendingApproval(): Promise<Watermark> {
    return ApiService.get(`/admin/companies/latest/watermark-approvals`);
  }

  /**
   * @endpoint /admin/companies/${companyId}/reject-watermark
   * @httpMethod POST
   * @param companyId
   * @param data
   */
  static rejectWatermark(
    companyId: number,
    data: FormData
  ): Promise<UpdateWatermarkResponse> {
    return ApiService.post(
      `/admin/companies/${companyId}/reject-watermark`,
      data
    );
  }

  /**
   * @endpoint /admin/companies/${companyId}/approve-watermark
   * @httpMethod POST
   * @param companyId
   * @param data
   * @param onUploadProgress
   */
  static approveWatermark(
    companyId: number,
    data: FormData,
    onUploadProgress: (event: AxiosProgressEvent) => void
  ): Promise<UpdateWatermarkResponse> {
    return ApiService.post(
      `/admin/companies/${companyId}/approve-watermark`,
      data,
      undefined,
      undefined,
      onUploadProgress
    );
  }

  /**
   * @endpoint /admin/companies/${companyId}/watermark-file
   * @httpMethod POST
   * @param companyId
   */
  static getWatermarkFile(companyId: number): Promise<FileWatermarkResponse> {
    return ApiService.get(`/admin/companies/${companyId}/watermark-file`);
  }

  /**
   * Get links to manage a Stripe subscription for a company
   * @endpoint /admin/companies/${companyId}/stripe-subscriptions/links
   * @httpMethod GET
   * @param companyId {number} CompanyImpersonation ID
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CompanySubscriptionLinks>}
   */
  static getSubscriptionLinks(
    companyId: number
  ): Promise<CompanySubscriptionLinks> {
    return ApiService.get(
      `/admin/companies/${companyId}/stripe-subscriptions/links`
    );
  }

  /**
   * 2FA status for the company
   * @endpoint /admin/companies/{company_id}/disable-2fa
   * @httpMethod POST
   * @param companyId {number}
   * @param is_disable
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CompanyUpdated>}
   */
  static set2FA(
    companyId: number,
    is_disable: number
  ): Promise<CompanyUpdated> {
    return ApiService.put(`/admin/companies/${companyId}/disable-2fa`, {
      disable_2fa: is_disable,
    });
  }

  /**
   * Update company email
   * @endpoint /admin/companies/${companyId}/email
   * @httpMethod PUT
   * @param companyId
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CompanyUpdated>}
   */
  static updateEmail(
    companyId: number,
    email: string,
    end: boolean
  ): Promise<CompanyUpdated> {
    return ApiService.put(`/admin/companies/${companyId}/email`, {
      email,
      end,
    });
  }
}
